import React from 'react';
import './ClosedBetaPage.css'; // Importing CSS for styling

const ClosedBetaPage: React.FC = () => {
  return (
    <div className="closed-beta-container">
      <div className="closed-beta-notice">
        <h2>Closed Beta</h2>
        <p>
          Phonetica is currently in a closed beta. If you want to be notified
          when early access launches, please join our Discord.
        </p>
        <a
          href="https://discord.gg/we8ZVSMgtG"
          target="_blank"
          rel="noreferrer"
          className="closed-beta-discord-link"
        >
          Join Discord
        </a>
      </div>
    </div>
  );
};

export default ClosedBetaPage;
