import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { LoginResponse } from '../../../interfaces/auth';
import './LoginPage.css';

interface LoginError {
  detail: string;
}

const LoginPage: React.FC = () => {
  const { isAuthenticated, login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [navigate, isAuthenticated]);

  useEffect(() => {
    // Check if the form is valid whenever any input changes
    setFormValid(email.trim() !== '' && password.trim() !== '');
  }, [email, password]);

  const handleGoogleLoginSuccess = async (codeResponse: CodeResponse) => {
    const authorizationCode = codeResponse.code;

    //console.log("Google Login Success: ", authorizationCode);

    try {
      const response = await fetch('/api/users/google_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: authorizationCode,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }), // Send the token to the backend
      });

      if (!response.ok) {
        const data: LoginError = await response.json();
        throw new Error(data.detail);
      }

      const data: LoginResponse = await response.json();
      login(data.access_token.token);
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.error('Login failed:', message);
      setErrorMessage(`${message}`); // Set error message
    }
  };

  const handleGoogleLoginFailure = () => {
    console.log('Google Login Failed');
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
  });

  const handleSubmit = async () => {
    try {
      const response = await fetch('/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, password: password }),
      });
      if (!response.ok) {
        const data: LoginError = await response.json();
        throw new Error(data.detail);
      }
      const data: LoginResponse = await response.json();
      login(data.access_token.token);
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.error('Login failed:', message);
      setErrorMessage(`${message}`); // Set error message
      setEmail(''); // Clear email field
      setPassword(''); // Clear password field
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h3 className="login-title">Login to your account</h3>
        <div className="login-input-group">
          <input
            type="text"
            placeholder="Email"
            className="login-input-field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="login-input-group">
          <input
            type="password"
            placeholder="Password"
            className="login-input-field"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="login-submit-button"
          disabled={!formValid}
          onClick={handleSubmit}
        >
          Login
        </button>
        <div className="login-signup-link-container">
          <p>
            Don't have an account?{' '}
            <Link to="/signup" className="login-signup-link">
              Signup
            </Link>
          </p>
        </div>
        <div className="strikethrough-line">
          <span>OR</span>
        </div>
        <div className="google-login">
          <button onClick={() => googleLogin()} className="google-login-button">
            <FaGoogle className="google-icon" /> Login with Google
          </button>
        </div>
        {errorMessage && <p className="login-error-message">{errorMessage}</p>}{' '}
        {/* Error message */}
      </div>
    </div>
  );
};

export default LoginPage;
