import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import DataTable from '../../../components/private/table/DataTable';
import { useUser } from '../../../context/UserContext';
import { Vocabulary } from '../../../interfaces/vocab';
import './VocabPage.css'; // Make sure to create a corresponding CSS file for styling

const VocabPage: React.FC = () => {
  const { userData } = useUser();
  const languageCode = userData?.learning_language_code;
  const [vocabulary, setVocabulary] = useState<Vocabulary[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const fetchVocabulary = useCallback(async () => {
    if (!languageCode) return;

    try {
      const response = await axios.get(`/api/vocabulary/${languageCode}`);
      setVocabulary(response.data);
    } catch (error) {
      console.error('Error fetching vocabulary:', error);
    } finally {
      setLoading(false);
    }
  }, [languageCode]);

  useEffect(() => {
    fetchVocabulary();
  }, [fetchVocabulary]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredVocabulary = vocabulary.filter(
    (word) =>
      word.word.toLowerCase().includes(searchQuery.toLowerCase()) ||
      word.meaning.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="phn-page-container">
      <h1>Vocabulary</h1>
      <div className="phn-main-content">
        <input
          className="phn-search-input"
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <div className="vocab-data-table-container">
          <DataTable
            headers={['Word', 'Meaning', 'Times Seen', 'Last Seen']}
            rows={filteredVocabulary.map((entry) => [
              entry.word,
              entry.meaning,
              entry.seen_count,
              format(parseISO(entry.last_seen), 'MM/dd/yy'),
            ])}
            widths={['auto', 'auto', '15%', '18%']}
            onRowClick={() => {}} // Add a function here if you want to handle row clicks
            isSelectable={false}
            loading={loading}
            itemsPerPage={20}
          />
        </div>
      </div>
    </div>
  );
};

export default VocabPage;
