import axios from 'axios';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserData } from '../interfaces/users';
import { useAuth } from './AuthContext';

interface UserContextTypeProps {
  userData: UserData | null;
  refreshUser: () => void;
}

const UserContext = createContext<UserContextTypeProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const [userData, setUserData] = useState<UserData | null>(null);

  const refreshUser = useCallback(async () => {
    try {
      const response = await axios.get('/api/users/whoami');
      const data = response.data;
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data: ', error);
    }
    console.log('refreshed user');
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      refreshUser();
    }
  }, [refreshUser, isAuthenticated]);

  const userContextValue = useMemo(
    () => ({
      userData,
      refreshUser,
    }),
    [userData, refreshUser]
  );

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
