import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { Card } from '../../interfaces/cards';

interface EditCardPopupProps {
  card: Card;
  language_code: string;
  onClose: () => void; // Function to close the popup
  onSubmit: () => void; // Function to submit the popup
}

const EditCardPopup: React.FC<EditCardPopupProps> = ({
  card,
  language_code,
  onClose,
  onSubmit,
}) => {
  const [cardNativeLanguage, setCardNativeLanguage] = useState(
    card.native_language
  );
  const [cardTargetLanguage, setCardTargetLanguage] = useState(
    card.target_language
  );
  const [cardPhonetic, setCardPhonetic] = useState(card.phonetic);
  const [cardFormality, setCardFormality] = useState(card.formality);
  let phoneticName = 'Phonetic';

  if (language_code.includes('zh-')) {
    phoneticName = 'Pinyin';
  } else if (language_code === 'ja') {
    phoneticName = 'Rōmaji';
  }

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const debouncedUpdatePhonetic = useCallback(
    (targetLanguage: string) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(async () => {
        const response = await axios.post('/api/generate/phonetics', {
          sentence: targetLanguage,
          language_code: language_code,
        });
        const data = response.data;
        setCardPhonetic(data.phonetic);
      }, 500);
    },
    [language_code]
  );

  const handleSave = async () => {
    try {
      let cardData: {
        native_language: string;
        target_language: string;
        formality: string;
        phonetic?: string | null;
      } = {
        native_language: cardNativeLanguage,
        target_language: cardTargetLanguage,
        formality: cardFormality,
      };

      if (
        cardPhonetic !== null &&
        cardPhonetic !== undefined &&
        cardPhonetic !== ''
      ) {
        cardData.phonetic = cardPhonetic;
      }

      await axios.put(`/api/cards/update/${card.id}`, cardData);
      // Close the popup after successful update
      onSubmit();
    } catch (error) {
      console.error('Error updating card:', error);
      onClose();
    }
  };

  const handleTargetLanguageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCardTargetLanguage(e.target.value);
    if (cardPhonetic) {
      debouncedUpdatePhonetic(e.target.value);
    }
  };

  const formalities = ['casual', 'neutral', 'polite'];

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Edit Card</h2>
        <div className="phn-popup-form">
          <label>
            Native Language
            <input
              id="nativeLanguage"
              type="text"
              value={cardNativeLanguage}
              onChange={(e) => setCardNativeLanguage(e.target.value)}
            />
          </label>

          <label htmlFor="targetLanguage">
            Target Language
            <input
              id="targetLanguage"
              type="text"
              value={cardTargetLanguage}
              onChange={handleTargetLanguageChange}
            />
          </label>

          {cardPhonetic && (
            <label htmlFor="phonetic">
              {phoneticName}
              <input
                id="phonetic"
                type="text"
                value={cardPhonetic}
                onChange={(e) => setCardPhonetic(e.target.value)}
              />
            </label>
          )}
          <label htmlFor="formality">
            Formality
            <select
              id="language"
              value={cardFormality}
              onChange={(e) => setCardFormality(e.target.value)}
            >
              {formalities.map((formality, index) => (
                <option key={index} value={formality}>
                  {formality}
                </option>
              ))}
            </select>
          </label>

          <div className="phn-popup-button-container">
            <button className="phn-button" onClick={handleSave}>
              Save
            </button>
            <button className="phn-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCardPopup;
