import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa'; // Importing icons from react-icons library
import './ChangePasswordPopup.css';

interface ChangePasswordPopupProps {
  onClose: () => void;
  onSubmit: () => void;
}

const ChangePasswordPopup: React.FC<ChangePasswordPopupProps> = ({
  onClose,
  onSubmit,
}) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleNewPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPassword = event.target.value;
      setNewPassword(newPassword);
      setPasswordValid(newPassword.length >= 8);
      setPasswordMatch(newPassword === confirmNewPassword);
    },
    [confirmNewPassword]
  );

  const handleConfirmPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newConfirmPassword = event.target.value;
      setConfirmNewPassword(newConfirmPassword);
      setPasswordMatch(newPassword === newConfirmPassword);
    },
    [newPassword]
  );

  const handleSubmit = async () => {
    setErrorMessage('');

    try {
      const response = await axios.put('/api/users/change_password', {
        current_password: currentPassword,
        new_password: newPassword,
      });
      if (response.data.message === 'Password updated successfully') {
        onSubmit();
      } else {
        setErrorMessage(response.data.message);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setPasswordValid(false);
        setPasswordMatch(false);
      }
    } catch (error: any) {
      console.error('Failed to update password:', error);
    }
  };

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Change Password</h2>
        <div className="phn-popup-form">
          <label>
            Current Password:
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </label>
          <label>
            New Password:
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handleNewPasswordChange}
              required
            />
          </label>
          {!passwordValid && (
            <div className="change-password-password-strength">
              Password must be at least 8 characters long.
            </div>
          )}
          <label>
            Confirm New Password:
            <div className="change-password-password-confirm">
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              {confirmNewPassword && (
                <div className="change-password-password-match">
                  {passwordMatch ? (
                    <FaCheck className="change-password-green-check" />
                  ) : (
                    <FaTimes className="change-password-red-cross" />
                  )}
                </div>
              )}
            </div>
          </label>
          {errorMessage && (
            <div className="change-password-error-message">{errorMessage}</div>
          )}
          <div className="phn-popup-button-container">
            <button
              className="phn-button"
              disabled={!currentPassword || !passwordMatch}
              onClick={handleSubmit}
            >
              Update
            </button>
            <button className="phn-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPopup;
