import axios, { InternalAxiosRequestConfig } from 'axios';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Token } from '../interfaces/auth';

interface AuthContextTypeProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  logout: () => void;
  login: (token: string) => void;
}

const AuthContext = createContext<AuthContextTypeProps | undefined>(undefined);

declare module 'axios' {
  export interface InternalAxiosRequestConfig {
    _retry?: boolean;
  }
}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const login = useCallback((token: string) => {
    console.log('log in');
    setAccessToken(token);
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(async () => {
    console.log('log out');
    try {
      await axios.post('/api/users/logout');
      setAccessToken(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  }, []);

  const refreshToken = useCallback(async () => {
    setIsLoading(true);
    try {
      // Perform token refresh logic here
      const response = await axios.get('/api/users/refresh');
      const data: Token = response.data;
      setIsAuthenticated(true);
      setAccessToken(data.token);
      return data.token;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      setIsAuthenticated(false);
      setAccessToken(null);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    const fetchToken = async () => {
      await refreshToken();
    };
    fetchToken();
  }, [refreshToken]);

  useLayoutEffect(() => {
    const authInterceptor = axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers.Authorization =
          !config._retry && accessToken
            ? `Bearer ${accessToken}`
            : config.headers.Authorization;
        return config;
      }
    );

    return () => {
      axios.interceptors.request.eject(authInterceptor);
    };
  }, [accessToken]);

  useLayoutEffect(() => {
    const refreshInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
          try {
            originalRequest._retry = true;
            const token = await refreshToken();
            if (token === null) {
              throw new Error('Failed to refresh token');
            }
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } catch (error) {
            console.error('Failed to refresh token:', error);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(refreshInterceptor);
    };
  }, [refreshToken]);

  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      logout,
      login,
    }),
    [isAuthenticated, isLoading, logout, login]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
