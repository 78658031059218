import React from 'react';
import TranslationTooltip from '../translationTooltip/TranslationTooltip';
import './PassageWithTranslation.css';

interface PassageWithTranslationProps {
  original: string[];
  translations: string[];
  phonetics: string[] | null;
  savedWords: string[];
  showPhonetics: boolean;
  languageCode: string;
}

const PassageWithTranslation: React.FC<PassageWithTranslationProps> = ({
  original,
  translations,
  phonetics,
  savedWords,
  showPhonetics,
  languageCode,
}) => {
  if (original.length !== translations.length) {
    throw new Error('Mismatch between original and translations lengths');
  }

  const gap =
    languageCode.includes('zh') || languageCode.includes('ja') ? '0px' : '5px';

  return (
    <div className="passage" style={{ gap: gap }}>
      {original.map((word, index) =>
        translations[index] ? (
          <TranslationTooltip
            key={index}
            word={word}
            translation={translations[index]}
            phonetic={phonetics ? phonetics[index] : null}
            showPhonetics={showPhonetics}
            saved={savedWords.indexOf(word) > -1}
          >
            <span className="word">{word}</span>
          </TranslationTooltip>
        ) : (
          <span className="tooltip-content">
            {phonetics && (
              <span
                style={{ visibility: showPhonetics ? 'hidden' : 'visible' }}
              >
                {word}
              </span>
            )}
            <span key={index} className="word">
              {word}
            </span>
          </span>
        )
      )}
    </div>
  );
};

export default PassageWithTranslation;
