// src/pages/ReviewPage.tsx
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { FaEdit, FaRegThumbsDown, FaRegThumbsUp, FaUndo } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import PlayButton from '../../../components/private/playButton/PlayButton';
import { useUser } from '../../../context/UserContext';
import { CardData, CardField } from '../../../interfaces/cards';
import EditCardPopup from '../../../popups/editCard/EditCardPopup';
import './ReviewPage.css';

const ReviewPage: React.FC = () => {
  const { userData, refreshUser } = useUser();
  const [deckId, setDeckId] = useState<number>();
  const [cards, setCards] = useState<CardData[]>([]);
  const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [passProgress, setPassProgress] = useState<number>(0);
  const [failProgress, setFailProgress] = useState<number>(0);
  const [audioKey, setAudioKey] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [editingCard, setEditingCard] = useState<CardData | null>(null);

  useEffect(() => {
    const state = location.state as { deckId?: number };
    if (location.state && state.deckId) {
      setDeckId(state.deckId);
    }
  }, [location.state]);

  const fetchReviewData = useCallback(async () => {
    try {
      const response = await axios.get(`/api/decks/${deckId}/review`);
      const data: CardData[] = response.data;
      setCards(data);
    } catch (error) {
      console.error('Error fetching deck review data:', error);
    }
  }, [deckId]);

  useEffect(() => {
    if (deckId) {
      fetchReviewData();
    }
  }, [fetchReviewData, deckId]);

  const handleShowAnswer = () => {
    setShowAnswer(true);
    setAudioKey((prevKey) => prevKey + 1); // Increment the audio key to trigger a re-render
  };

  const handleNextCard = () => {
    setShowAnswer(false);
    setCurrentCardIndex(currentCardIndex + 1);
  };

  const handleCardResult = async (isPass: boolean) => {
    try {
      const cardId = cards[currentCardIndex].id;
      const endpoint = isPass ? 'pass' : 'fail';
      await axios.put(`/api/cards/${cardId}/${endpoint}`);

      if (currentCardIndex === 0) {
        refreshUser();
      }

      if (isPass) {
        setPassProgress(passProgress + 1);
      } else {
        setFailProgress(failProgress + 1);
      }

      setCards((prevCards) => {
        const updatedCards = [...prevCards];
        updatedCards[currentCardIndex] = {
          ...updatedCards[currentCardIndex],
          pass: isPass,
        };
        return updatedCards;
      });

      handleNextCard();
    } catch (error) {
      console.error(
        `Error marking card as ${isPass ? 'passed' : 'failed'}:`,
        error
      );
    } finally {
      setDisableButtons(false);
    }
  };

  const handlePass = () => handleCardResult(true);
  const handleFail = () => handleCardResult(false);

  const handleUndo = async () => {
    setDisableButtons(true);
    try {
      const card = cards[currentCardIndex - 1];
      const cardState = {
        id: card.id,
        interval: card.interval,
        repetitions: card.repetitions,
        ease_factor: card.ease_factor,
        next_review_at: card.next_review_at,
        last_seen: card.last_seen,
        is_new: card.is_new,
        is_learning: card.is_learning,
      };
      await axios.put(`/api/cards/${card.id}/undo`, cardState);

      if (card.pass) {
        setPassProgress(passProgress - 1);
      } else {
        setFailProgress(failProgress - 1);
      }

      setShowAnswer(false);
      setCurrentCardIndex((prevIndex) => prevIndex - 1);
    } catch (error) {
      console.error('Error marking card as failed:', error);
    } finally {
      setDisableButtons(false);
    }
  };

  const handleEditCard = () => {
    if (cards[currentCardIndex]) {
      setEditingCard(cards[currentCardIndex]);
    }
  };

  const handleCloseEditPopup = () => {
    setEditingCard(null);
  };

  const handleSubmitEdit = async () => {
    if (editingCard) {
      try {
        // Fetch the updated card data
        const response = await axios.get(`/api/cards/${editingCard.id}`);
        const fetchedCard = response.data;

        // Update only specific fields
        const updatedCardData = {
          native_language: fetchedCard.native_language,
          target_language: fetchedCard.target_language,
          phonetic: fetchedCard.phonetic,
          formality: fetchedCard.formality,
        };

        // Update all cards with the same note_id
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.note_id === editingCard.note_id) {
              console.log('Updating card:', card.note_id);
              return {
                ...card,
                ...updatedCardData,
              };
            }
            return card;
          });
        });

        setEditingCard(null);
      } catch (error) {
        console.error('Error fetching updated card data:', error);
      }
    }
  };

  const renderLayout = (
    layout: string[][],
    fields: CardField[],
    cardData: CardData
  ) => {
    return layout.map((row, rowIndex) => (
      <div key={rowIndex} className="layout-row">
        {row.map((fieldKey, fieldIndex) => {
          const fieldConfig = fields.find((field) => field.field === fieldKey);
          if (fieldConfig) {
            const value = cardData[fieldConfig.field as keyof CardData];
            // Check if the value is renderable (string or number)
            if (typeof value === 'string' || typeof value === 'number') {
              // Special handling for "audio" field
              if (fieldConfig.field === 'audio') {
                if (cardData.audio) {
                  return (
                    <PlayButton
                      key={`${fieldIndex}-${audioKey}`} // Add audioKey to force re-render
                      disabled={disableButtons}
                      src={cardData.audio}
                      isLoading={false}
                      autoPlay={true} // Set autoPlay to true when answer is shown
                    />
                  );
                }
              } else if (fieldConfig.field === 'formality') {
                if (cardData.formality) {
                  return (
                    <div
                      key={`${fieldIndex}-${audioKey}`} // Add audioKey to force re-render
                      className="card-field"
                      style={fieldConfig.style}
                    >
                      ({cardData.formality})
                    </div>
                  );
                }
              } else {
                return (
                  <div
                    key={fieldIndex}
                    className="card-field"
                    style={fieldConfig.style}
                  >
                    {value}
                  </div>
                );
              }
            }
          }
          return null;
        })}
      </div>
    ));
  };

  const reviewComplete = currentCardIndex >= cards.length && cards.length > 0;

  return (
    <div className="review-page">
      <div className="progress-bar">
        {passProgress !== 0 && (
          <div
            className="pass-progress"
            style={{ width: `${(passProgress / cards.length) * 100}%` }}
          />
        )}
        {failProgress !== 0 && (
          <div
            className="fail-progress"
            style={{ width: `${(failProgress / cards.length) * 100}%` }}
          />
        )}
        {cards.length - currentCardIndex > 0 && (
          <div
            className="uncomplete-progress"
            style={{
              width: `${((cards.length - (passProgress + failProgress)) / cards.length) * 100}%`,
            }}
          />
        )}
      </div>
      <>
        {reviewComplete ? (
          <>
            <Confetti
              recycle={false}
              numberOfPieces={200} // Adjust this number for more or fewer pieces
              gravity={0.2}
            />
            <div className="complete-container">
              <div className="all-reviewed">
                <h2>Review Finished</h2>
                <div className="review-percentage">
                  <h3>
                    Passed:{' '}
                    <span style={{ color: 'green' }}>
                      {Math.round((passProgress / cards.length) * 100)}%
                    </span>
                  </h3>
                  <h3>
                    Failed:{' '}
                    <span style={{ color: 'red' }}>
                      {Math.round((failProgress / cards.length) * 100)}%
                    </span>
                  </h3>
                </div>
                <button
                  className="return-home-button"
                  onClick={() => navigate('/dashboard')}
                >
                  Return Home
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="review-card-actions">
              <button
                className="phn-text-button"
                style={{
                  width: '140px',
                }}
                onClick={handleEditCard}
              >
                Edit <FaEdit />
              </button>
              <span className="review-card-progress">
                {passProgress + failProgress + 1} / {cards.length}
              </span>
              <button
                className="phn-text-button"
                style={{
                  width: '140px',
                }}
                disabled={currentCardIndex <= 0}
                onClick={handleUndo}
              >
                Undo <FaUndo />
              </button>
            </div>
            <div className="card-container">
              {cards.length > 0 ? (
                <div className="phn-white-card card-display">
                  <div className="card-front">
                    {renderLayout(
                      cards[currentCardIndex].front.layout,
                      cards[currentCardIndex].front.fields,
                      cards[currentCardIndex]
                    )}
                  </div>
                  {showAnswer && (
                    <div className="card-back">
                      {renderLayout(
                        cards[currentCardIndex].back.layout,
                        cards[currentCardIndex].back.fields,
                        cards[currentCardIndex]
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="button-container">
              {!showAnswer ? (
                <button
                  className="show-answer-button"
                  onClick={handleShowAnswer}
                >
                  Show Answer
                </button>
              ) : (
                <>
                  <button
                    className="pass-button"
                    onClick={handlePass}
                    disabled={disableButtons}
                  >
                    Pass
                    <FaRegThumbsUp />
                  </button>
                  <button
                    className="fail-button"
                    onClick={handleFail}
                    disabled={disableButtons}
                  >
                    Fail
                    <FaRegThumbsDown />
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </>
      {editingCard && (
        <EditCardPopup
          card={editingCard}
          language_code={userData?.learning_language_code || ''}
          onClose={handleCloseEditPopup}
          onSubmit={handleSubmitEdit}
        />
      )}
    </div>
  );
};

export default ReviewPage;
